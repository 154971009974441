//======================================
//  Colors
//======================================

// Neutrals
$white: #fff;
$gray-xlight: #efefef;
$gray-light: #9a989a;
$gray: #585a5c;
$gray-dark: #141517;
$black: #000;

// Primary
$beige: #e8e5dd;
$beige-light: #f5f3ef;
$blue: #002761;
$blue-med: #003278;
$blue-light: #3674b7;
$blue-xlight: #95bfe5;
$green: #7acc24;
$green-light: #ade271;
$orange: #e3760f;
$orange-dark: #d24128;
$purple: #633ea0;
$sand: #ebdbb5;


//======================================
//    Gradients
//======================================

$grad-white-bluexlight: linear-gradient(to bottom, $white 0, $blue-xlight 100%);
$grad-white-greenlight: linear-gradient(to bottom, $white 0, $green-light 100%);
$grad-white-beige: linear-gradient(to bottom, $white 0, $beige 100%);


//======================================
//    Fonts, weights
//======================================

$sans: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$playfair: 'Playfair Display', serif;

$normal: 400;
$bold: 700;


//======================================
//    Breakpoints
//======================================

$breakpoints: (
    'sm-mobile': 20em,        // 320px
    'mobile': 23.4375em,      // 375px
    'lg-mobile': 25em,        // 400px
    'phablet': 37.5em,        // 600px
    'sm-tablet': 46em,        // 736px
    'tablet': 47.9375em,      // 767px
    'tablet-vert': 48em,      // 768px
    'lg-tablet': 50em,        // 800px
    'xs-desktop': 57.5em,     // 920px
    'sm-desktop': 62.5em,     // 1000px
    'tablet-horiz': 64em,     // 1024px
    'desktop': 75em,          // 1200px
    'md-desktop': 87.5em,     // 1400px
    'lg-desktop': 100em       // 1600px
);

// global breakpoints
$main-breakpoint: tablet-vert;          // Where the layout will all stack for mobile
$secondary-breakpoint: sm-desktop;      // Where narrow-width columns will stack before main breakpoint

//menu breakpoints
$mobile-breakpoint: phablet;            // Breaks to mobile
$size-breakpoint: tablet-horiz;    // Adjustments for sizing
