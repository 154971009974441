//scss-lint:disable all
@import 'partials/variables';

body {
    background: $grad-white-greenlight;
}

.login {
    h1 a,
    .wp-login-logo a {
        background-color: transparent;
        background-image: url('../images/logo/lifeline-of-ohio.svg');
        background-position: center center;
        background-size: contain;
        height: 100px;
        padding: 24px;
        width: 250px;
    }

    form {
        border-radius: 0.25rem;
        position: relative;
    }
}
